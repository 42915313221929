import { API_MAYA_PK_SK_BASE64, API_MAYA_SK_BASE64 } from "@env";
import * as Types from "@src/ducks/types";
import * as api from "./request";

export const appConfig = () =>
  api.callGet("/app-config");

export const login = (params: Types.LoginInput) =>
  api.callPost("/auth/login", params, "NO_TOKEN");

export const refreshToken = (params: Types.SessionValue) =>
  api.callPost("/auth/refresh-token", params);

export const forgotpassword = (params: Types.LoginInput) =>
  api.callPost("/auth/forgot-password", params);

export const resetpassword = (params: Types.ResetPasswordInput) =>
  api.callPut("/auth/reset-password", params);

export const register = (params: Types.SignupInput) =>
  api.callPost("/auth/register", params);

export const userInfo = (params: Types.SessionValue) =>
  api.callGet("/user/" + params.id, params.accessToken);

export const userSubscription = (params: Types.SessionValue) =>
  api.callGet("/user/subscription/" + params.id, params.accessToken);

export const updateUser = (params: Types.UpdateUserValue, accessToken: string) =>
  api.callPatch("/user/" + params.id, params, accessToken);

export const signsecureCreateDocument = (params: any, accessToken: string) =>
  api.callFormData("/directory/signsecure/create", params, accessToken);

export const signsecureFinalDraftDocument = (params: any, accessToken: string) =>
  api.callFormData("/directory/signsecure/finalize-draft", params, accessToken);

export const signsecureSaveDocument = (params: any, accessToken: string) =>
  api.callFormData("/directory/signsecure/draft", params, accessToken);

export const signsecurePatchDraftDocument = (params: any, accessToken: string) =>
  api.callFormDataPatch("/directory/signsecure/draft", params, accessToken);

export const signsecureImportDocument = (params: any, accessToken: string) =>
  api.callFormData("/directory/signsecure/import", params, accessToken);  

export const signsecureDownloadDocument = (document_id: string, accessToken: string) =>
  api.callGet("/directory/proxy/" + document_id, accessToken, "blob");

export const signsecureDirectoryDownload = (document_id: string, accessToken: string) =>
  api.callGet("/directory/download/" + document_id, accessToken, "blob");

export const signsecureVerifyUser = (document_id: string, accessToken: string) =>
  api.callPost("/directory/verify-user/" + document_id, {}, accessToken);

export const signsecureViewDocument = (document_id: string, accessToken: string) =>
  api.callPost("/directory/signsecure/view/" + document_id, {}, accessToken);

export const signsecureLockDocument = (document_id: string, accessToken: string) =>
  api.callGet("/directory/lock/" + document_id, accessToken);

export const signsecureSignDocument = (document_id: string, params: any, accessToken: string) =>
  api.callFormData("/directory/signsecure/sign/" + document_id, params, accessToken);

export const signsecureCompleteDocument = (document_id: string, params: any, accessToken: string) =>
  api.callFormData("/directory/signsecure/complete/" + document_id, params, accessToken);

export const signsecureSignCompleteDocument = (document_id: string, params: any, accessToken: string) =>
  api.callFormData("/directory/signsecure/sign-complete/" + document_id, params, accessToken);

export const signsecureApproveDocument = (params: Types.ApproveDocument, accessToken: string) =>
  api.callPost("/directory/signsecure/approve/" + params.id, params, accessToken);

export const signsecureApproveCompleteDocument = (document_id: string, params: any, accessToken: string) =>
  api.callFormData("/directory/signsecure/approve-complete/" + document_id, params, accessToken);

export const signsecureRejectDocument = (params: Types.CancelDocument, accessToken: string) =>
  api.callPost("/directory/signsecure/reject/" + params.id, { reason: params.reason }, accessToken);

export const signsecureCancelDocument = (params: Types.CancelDocument, accessToken: string) =>
  api.callPost("/directory/signsecure/cancel/" + params.id, { reason: params.reason }, accessToken);

export const signsecureDeclineDocument = (params: Types.CancelDocument, accessToken: string) =>
  api.callPost("/directory/signsecure/decline/" + params.id, { reason: params.reason }, accessToken);

export const directoryDetails = (document_id: string, accessToken: string) =>
  api.callGet("/directory/" + document_id, accessToken);

export const signsecureEncryptPDF = ( id: string, params: any, accessToken: string) =>
  api.callFormData("/directory/encrypt-pdf/" + id, params, accessToken);

export const signsecureDecryptPDF = ( id: string, params: any, accessToken: string) =>
  api.callFormData("/directory/decrypt-pdf/" + id, params, accessToken);

// UPLOAD SIGNATURE
export const createSignature = (params: any, accessToken: string) =>
  api.callFormData("/signature", params, accessToken);

export const signatureDefault = (accessToken: string) =>
  api.callGet("/signature/default", accessToken);

export const downloadSignature = (signatureId: string, accessToken: string) =>
  api.callGet("/signature/proxy/" + signatureId, accessToken, "blob");

export const generateOTP = (params: any) =>
  api.callPost("/user/generate-otp", { isTest: false }, params.accessToken);

export const validateOTP = (params: any) =>
  api.callGet("/user/validate-otp?otp=" + params.otp, params.accessToken);


// MESSAGES
export const getInboxDetails = (id: string, accessToken: string) =>
  api.callGet("/messages/data/" + id, accessToken);

export const updateMessageDetails = (params: any, accessToken: string) =>
  api.callPut("/messages/update/", params, accessToken);

export const updateReadUnreadMessages = (params: any, accessToken: string) =>
  api.callPatch("/messages/read/", params, accessToken);

export const deleteAllMessages = (id: string, accessToken: string) =>
  api.callDelete("/messages/" + id, accessToken);

// CALL IP INFO
export const getIpInfo = () =>
  api.callIpInfo();

// FACE ID Login
export const loginFaceID = (params: Types.FaceID) =>
  api.callPost("/auth/face-login", params, "NO_TOKEN");
  
// FACE ID Register
export const registerFaceID = (params: any) =>
  api.callPost("/auth/face-id", params);

// GENERATE MAYA TOKEN
export const generateMayaToken = (params: {card: Types.CardDetails}) =>
  api.callMayaPost("/payment-tokens", params, API_MAYA_PK_SK_BASE64);

// CREATE MAYA CUSTOMER
export const createMayaCustomer = (params: any) =>
  api.callMayaPost("/customers", params, API_MAYA_SK_BASE64);

// Link tokenized card to the customer
export const linkGeneratedMayaTokenToCustomer = (params: Types.LinkMayaTokenToCustomer) =>
  api.callMayaPost(`/customers/${params.customerId}/cards`, params, API_MAYA_SK_BASE64);

// PAY THE TRANSACTION - MAYA
export const paymayaTransaction = (customerId: string, cardToken: string, params: Types.MAYATransaction) =>
  api.callMayaPost(`/customers/${customerId}/cards/${cardToken}/payments`, params, API_MAYA_SK_BASE64);

// RETRIEVE CARD DETAILS FROM MAYA
export const getMayaCardDetails = (customerId: string, cardToken: string) =>
  api.callMayaGet(`/customers/${customerId}/cards/${cardToken}`, API_MAYA_SK_BASE64);

// DELETE CARD DETAILS FROM MAYA
export const deleteMayaCardDetails = (customerId: string, cardToken: string) =>
  api.callMayaDelete(`/customers/${customerId}/cards/${cardToken}`, API_MAYA_SK_BASE64);