// DUCKS pattern
import { createAction, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@src/ducks/store";
import { FileValue } from "@src/screens/create-document/provider/types";
import moment from "moment";
import { CancelDocument, CreateDocumentRequestValue, DraftDocumentValue, ErrorValue, ImportDocumentRequestValue } from "../types";

export interface initialStateValue {
  routes: string;
  createdDocument: CreateDocumentRequestValue[];
  importedDocument: ImportDocumentRequestValue[];
  loading: boolean;
  savingDraft: boolean;
  currentLoading: string;
  error: ErrorValue;
  document: CreateDocumentRequestValue;
  draftDocument: DraftDocumentValue;
  affixSignature: CreateDocumentRequestValue;
  approveDocument: CreateDocumentRequestValue;
  cancelDocument: CreateDocumentRequestValue;
  declineDocument: CreateDocumentRequestValue;
  rejectDocument: CreateDocumentRequestValue;
  enableQuickSigning: boolean;

  files: FileValue[];
  shareable: boolean;
  foldername: string;
  pdfEncryptPassword: boolean;
}

export const initialState: initialStateValue = {
  routes: "",
  createdDocument: [] as any,
  draftDocument: {} as DraftDocumentValue,
  loading: false,
  enableQuickSigning: false,
  currentLoading: "",
  error: {} as ErrorValue,
} as initialStateValue;

// Slice
export const signsecureSlice = createSlice({
  name: "signsecure",
  initialState: initialState,
  reducers: {
    saveDocumentRequest: (state) => {
      state.savingDraft = true;
      state.currentLoading = "Saving";
    },
    saveDocumentRequestSuccess: (state, action) => {
      state.createdDocument = action.payload;
      state.currentLoading = "";
      state.savingDraft = false;
    },

    createDocumentRequest: (state) => {
      state.loading = true;
      state.currentLoading = "Creating";
    },
    createDocumentRequestSuccess: (state, action) => {
      state.createdDocument = action.payload;
      state.currentLoading = "";
      state.loading = false;
    },
    createDocumentRequestFailed: (state, action) => {
      state.error = action.payload;
      state.currentLoading = "";
      state.loading = false;
      state.savingDraft = false;
    },

    importDocumentRequest: (state) => {
      state.loading = true;
      state.currentLoading = "Importing";
    },
    importDocumentRequestSuccess: (state, action) => {
      state.importedDocument = action.payload;
      state.currentLoading = "Completed";
      state.loading = false;
    },
    importDocumentRequestFailed: (state, action) => {
      state.error = action.payload;
      state.currentLoading = "";
      state.loading = false;
    },

    viewDocumentRequest: (state) => {
      state.document = {} as CreateDocumentRequestValue;
      state.error = {} as ErrorValue;
      state.loading = true;
    },
    viewDocumentSuccess: (state, action) => {
      state.document = action.payload;
      state.loading = false;
    },
    viewDocumentFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    affixSignatureRequest: (state) => {
      state.loading = true;
      state.currentLoading = "Signing";
    },
    affixSignatureSuccess: (state, action) => {
      state.affixSignature = action.payload;
      state.currentLoading = "";
      state.loading = false;
    },
    affixSignatureFailed: (state, action) => {
      state.error = action.payload;
      state.currentLoading = "";
      state.loading = false;
    },

    approveDocumentRequest: (state) => {
      state.loading = true;
      state.currentLoading = "Approving";
    },
    approveDocumentSuccess: (state, action) => {
      state.approveDocument = action.payload;
      state.currentLoading = "";
      state.loading = false;
    },
    approveDocumentFailed: (state, action) => {
      state.error = action.payload;
      state.currentLoading = "";
      state.loading = false;
    },

    cancelDocumentRequest: (state) => {
      state.loading = true;
      state.currentLoading = "Cancelling";
    },
    cancelDocumentSuccess: (state, action) => {
      state.cancelDocument = action.payload;
      state.currentLoading = "";
      state.loading = false;
    },
    cancelDocumentFailed: (state, action) => {
      state.error = action.payload;
      state.currentLoading = "";
      state.loading = false;
    },

    declineDocumentRequest: (state) => {
      state.currentLoading = "Declining";
      state.loading = true;
    },
    declineDocumentSuccess: (state, action) => {
      state.declineDocument = action.payload;
      state.currentLoading = "";
      state.loading = false;
    },
    declineDocumentFailed: (state, action) => {
      state.error = action.payload;
      state.currentLoading = "";
      state.loading = false;
    },

    rejectDocumentRequest: (state) => {
      state.currentLoading = "Rejecting";
      state.loading = true;
    },
    rejectDocumentSuccess: (state, action) => {
      state.rejectDocument = action.payload;
      state.currentLoading = "";
      state.loading = false;
    },
    rejectDocumentFailed: (state, action) => {
      state.error = action.payload;
      state.currentLoading = "";
      state.loading = false;
    },

    saveSignsecureInputs: (state, action) => {
      state.files = action.payload.files;
      state.shareable = action.payload.shareable;
      state.foldername = action.payload.foldername;
      state.pdfEncryptPassword = action.payload.pdfEncryptPassword;
    },
    pdfEncryptPassword: (state,action) => {
      state.pdfEncryptPassword = action.payload.pdfEncryptPassword;
    },
    resetError: (state) => {
      state.error = {} as ErrorValue;
    },
    resetSignSecure: (state) => {
      return {...initialState, routes: state.routes};
    },
    setRoutes: (state, action) => {
      state.routes = action.payload;
    },
    enableQuickSigning: (state, action) => {
      state.enableQuickSigning = action.payload;
    },

    saveDraftDocument: (state, action) => {
      state.draftDocument = action.payload;
    },
  },
});

// Actions
export const signsecureActions = {
  saveDocumentRequest: signsecureSlice.actions.saveDocumentRequest,
  saveDocumentRequestSuccess: signsecureSlice.actions.saveDocumentRequestSuccess,
  
  createDocumentRequest: signsecureSlice.actions.createDocumentRequest,
  createDocumentRequestSuccess: signsecureSlice.actions.createDocumentRequestSuccess,
  createDocumentRequestFailed: signsecureSlice.actions.createDocumentRequestFailed,

  importDocumentRequest: createAction(
    `${signsecureSlice.name}/importDocumentRequest`,
    (params: CreateDocumentRequestValue) => ({
      payload: params,
    })
  ),
  importDocumentRequestSuccess: signsecureSlice.actions.importDocumentRequestSuccess,
  importDocumentRequestFailed: signsecureSlice.actions.importDocumentRequestFailed,

  viewDocumentRequest: createAction(
    `${signsecureSlice.name}/viewDocumentRequest`,
    (params: CreateDocumentRequestValue) => ({
      payload: params,
    })
  ),
  viewDocumentSuccess: signsecureSlice.actions.viewDocumentSuccess,
  viewDocumentFailed: signsecureSlice.actions.viewDocumentFailed,

  affixSignatureRequest: createAction(
    `${signsecureSlice.name}/affixSignatureRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  affixSignatureSuccess: signsecureSlice.actions.affixSignatureSuccess,
  affixSignatureFailed: signsecureSlice.actions.affixSignatureFailed,

  approveDocumentRequest: createAction(
    `${signsecureSlice.name}/approveDocumentRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  approveDocumentSuccess: signsecureSlice.actions.approveDocumentSuccess,
  approveDocumentFailed: signsecureSlice.actions.approveDocumentFailed,

  cancelDocumentRequest: createAction(
    `${signsecureSlice.name}/cancelDocumentRequest`,
    (params: CancelDocument) => ({
      payload: params,
    })
  ),
  cancelDocumentSuccess: signsecureSlice.actions.cancelDocumentSuccess,
  cancelDocumentFailed: signsecureSlice.actions.cancelDocumentFailed,

  declineDocumentRequest: createAction(
    `${signsecureSlice.name}/declineDocumentRequest`,
    (params: CancelDocument) => ({
      payload: params,
    })
  ),
  declineDocumentSuccess: signsecureSlice.actions.declineDocumentSuccess,
  declineDocumentFailed: signsecureSlice.actions.declineDocumentFailed,

  rejectDocumentRequest: createAction(
    `${signsecureSlice.name}/rejectDocumentRequest`,
    (params: CancelDocument) => ({
      payload: params,
    })
  ),
  rejectDocumentSuccess: signsecureSlice.actions.rejectDocumentSuccess,
  rejectDocumentFailed: signsecureSlice.actions.rejectDocumentFailed,

  saveSignsecureInputs: signsecureSlice.actions.saveSignsecureInputs,
  resetError: signsecureSlice.actions.resetError,
  resetSignSecure: signsecureSlice.actions.resetSignSecure,

  setRoutes: signsecureSlice.actions.setRoutes,
  enableQuickSigning: signsecureSlice.actions.enableQuickSigning,
  pdfEncryptPassword: signsecureSlice.actions.pdfEncryptPassword,
  // VIEW SAVE DRAFTS
  saveDraftDocument: signsecureSlice.actions.saveDraftDocument,
};

// Selectors
export const selectedSignSecureLoading = (state: RootState) => state.signsecure.loading;
export const selectedSignSecureSavingDraft = (state: RootState) => state.signsecure.savingDraft;
export const selectedSignSecureLoadingStatus = (state: RootState) => state.signsecure.currentLoading;
export const selectImportDocumentLoading = (state: RootState) => state.signsecure.loading;
export const selectImportDocumentLoadingStatus = (state: RootState) => state.signsecure.currentLoading;
export const selectImportDocument = (state: RootState) => state.signsecure.importedDocument || [];
export const selectCreateDocument = (state: RootState) => state.signsecure.createdDocument || [];
export const selectedCancelDocument = (state: RootState) => state.signsecure.cancelDocument;
export const selectedDeclineDocument = (state: RootState) => state.signsecure.declineDocument;
export const selectedRejectDocument = (state: RootState) => state.signsecure.rejectDocument;
export const selectedAffixDocument = (state: RootState) => state.signsecure.affixSignature;
export const selectedApproveDocument = (state: RootState) => state.signsecure.approveDocument;
export const selectedSignSecureError = (state: RootState) => state.signsecure.error;
export const selectedEnableQuickSigning = (state: RootState) => state.signsecure.enableQuickSigning;
export const selectedDraftDocument= (state: RootState) => state.signsecure.draftDocument;
export const selectedSignsecureInputs = createSelector(
  
  (state: any) => state.signsecure.files,
  (state: any) => state.signsecure.shareable,
  (state: any) => state.signsecure.foldername,
  (state: any) => state.signsecure.pdfEncryptPassword,
  (files, shareable, foldername, pdfEncryptPassword) => ({ files, shareable, foldername, pdfEncryptPassword })
);
export const selectedViewDocument = (state: RootState) => state.signsecure.document;
export const selectedCustomSignatureID = createSelector(
  (state: RootState) => state.auth.session,
  (session) => {
    const userId = session?.id ?? "";
    const last2Digit = userId.substr(userId.length -4);
    return `${last2Digit}${moment().format("YYMMDDhhmmss")}`;
  }
);
export const selectedDocumentParties = createSelector(
  (state: any) => state.signsecure.document || {},
  (state: any) => state.auth.session,
  (document, session) => {
    const email = session?.email ?? "";
    const parties = document?.workflow?.parties ?? [];

    return parties.find((item: any) => item.email === email) || {};
  }
);

export const selectedDocument = createSelector(
  (state: any) => state.signsecure.document || {},
  (state: any) => state.auth.session,
  (document, session) => {
    const email = session?.email ?? "";
    const parties = document?.workflow?.parties ?? [];

    return parties.find((item: any) => item.email === email) || {};
  }
);

export const selectedSignatureNotes = createSelector(
  (state: any) => state.signsecure.document || {},
  (state: any) => state.auth.session,
  (document, session) => {
    const email = session?.email ?? "";
    const parties = document?.workflow?.parties ?? [];
    const signOrder = document?.workflow?.signOrder ?? false;
    const currentParty = document?.workflow?.currentParty ?? "";

    if(signOrder && email !== currentParty){
      const currentSigner = parties.find((item: any) => item.email === currentParty) || {};
      if(currentSigner.role === "SIGN"){
        return `Waiting for ${currentParty} to sign the document.`;
      }else if(currentSigner.role === "APPROVE"){
        return `Waiting for ${currentParty} to approve the document.`;
      }else if(currentSigner.role === "COPY"){
        return "You are only allowed to view this document.";
      }
    }else{
      const currentSigner = parties.find((item: any) => item.email === email) || {};
      if(currentSigner.role === "SIGN"){
        return "Set a signature field and add your signature.";
      }else if(currentSigner.role === "APPROVE"){
        return "Please review the document for approval.";
      }else{
        return "You are only allowed to view this document.";
      }
    }
  }
);

export const selectedEnableSignature = createSelector(
  (state: any) => state.signsecure.document || {},
  (state: any) => state.auth.session,
  (document, session) => {
    const email = session?.email ?? "";
    const status = document?.workflow?.status ?? "";
    const parties = document?.workflow?.parties ?? [];
    const signOrder = document?.workflow?.signOrder ?? false;
    const currentOrder = document?.workflow?.currentOrder ?? 0;
    const Transaction = ["DECLINED", "REJECTED", "CANCELLED", "COMPLETED"];

    if(signOrder){
      const currentParty = parties[currentOrder] || {};
      if(currentParty.email === email && (!Transaction.includes(status) && currentParty.status !== "SIGNED")){
        return true;
      }
    }else{
      const currentSigner = parties.find((item: any) => item.email === email) || {};
      if(!Transaction.includes(status) && currentSigner.status !== "SIGNED"){
        return true;
      }
    }

    return false;
  }
);

export const selectedRoutesParams= createSelector(
  (state: RootState) => state.signsecure.routes,
  (route) => {
    if(route.includes("/document")){
      return {
        w: route.replace("/document/", ""),
        activeTab: "Affix Document",
      };
    }
    return undefined;
  }
);

// Reducer
export default signsecureSlice.reducer;
