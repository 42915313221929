import { SagaIterator } from "@redux-saga/core";
import { gtag } from "ga-gtag";
import { call, delay, put, select, takeEvery } from "redux-saga/effects";

// Types
import * as Types from "../types";

// API
import {
  forgotpassword,
  login,
  loginFaceID,
  refreshToken,
  register,
  resetpassword,
} from "@src/utils/api";

// Slice
import { authActions, selectAuthLoginInput } from "../slices/auth.slice";
import { dashboardActions } from "../slices/dashboard.slice";
import { forgotActions } from "../slices/forgot.slice";
import { signsecureActions } from "../slices/signsecure.slice";
import { signupActions } from "../slices/signup.slice";
import { userActions } from "../slices/user.slice";

function* handleSignin(action: {
  type: typeof authActions.loginRequest;
  payload: Types.LoginInput;
}): SagaIterator {
  try {
    const user = yield call(login, action.payload);

    yield put(authActions.loginSuccess({...user.data, loginType: "Login via Email"}));
    gtag("event", "login", { "method": "Login via Email" });

  } catch (error: any) {    
    const message = typeof error.error === "object" ? error.error[0] : error.message || error.error || "Somthing went wrong";
    const failedMessage = message?.replace(/^HttpException:\s*/, "");

    yield put(authActions.loginFailure({ message: failedMessage, status: error?.status ?? 500}));
    yield delay(1000);
    yield put(authActions.loginFailure({}));
  }
}

function* handleFaceIDSignin(): SagaIterator {
  try {
    const { email } = yield select(selectAuthLoginInput); 
    const user = yield call(loginFaceID, {email});
    const param ={ 
      id: user.data?.id,
      role: user.data?.role,
      userId: user.data?.id,
      email: user.data?.email,
      givenName: user.data?.givenName,
      lastName: user.data?.lastName,
      accessToken: user.data?.accessToken,
      refreshToken: user.data?.refreshToken,
      loginType: "Login via Face Recognition"
    };
    yield put(authActions.loginSuccess(param));
    gtag("event", "login", { "method": "Login via Face Recognition" });
  } catch (error: any) {
    console.log("error", error, typeof error.error);
    
    const message = typeof error.error === "object" ? error.error[0] : error.message || error.error || "Somthing went wrong";
    const failedMessage = message?.replace(/^HttpException:\s*/, "");

    yield put(authActions.loginFailure({ message: failedMessage }));
    yield delay(1000);
    // yield put(authActions.loginFailure({}));
  }
}

function* handleSignup(action: {
  type: typeof signupActions.signupRequest;
  payload: Types.SignupInput;
}): SagaIterator {
  try {
    // const body = yield call(SignUpFormDataParser, action.payload);
    const user = yield call(register, action.payload);

    yield put(signupActions.signupSuccess(user.data));
    yield delay(1000);
    yield put(signupActions.signupSuccess({}));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    const failedMessage = message?.replace(/^HttpException:\s*/, "");

    yield put(signupActions.signupFailure({ message: failedMessage }));
    yield delay(1000);
    yield put(signupActions.signupFailure({}));
  }
}

function* handleForgotpassword(action: {
  type: typeof forgotActions.forgotRequest;
  payload: Types.LoginInput;
}): SagaIterator {
  try {
    const user = yield call(forgotpassword, action.payload);

    yield put(forgotActions.forgotSuccess(user.data));
  } catch (error: any) {
    const message = error.message || error.error || "Somthing went wrong";
    const failedMessage = message?.replace(/^HttpException:\s*/, "");

    yield put(forgotActions.forgotFailed({ message: failedMessage }));
    yield delay(1000);
    yield put(forgotActions.forgotFailed({}));
  }
}

function* handleResetpassword(action: {
  type: typeof forgotActions.forgotRequest;
  payload: Types.ResetPasswordInput;
}): SagaIterator {
  try {
    const user = yield call(resetpassword, action.payload);

    yield put(forgotActions.resetSuccess(user.data));
  } catch (error: any) {
    const message = error.message || error.error || "Somthing went wrong";
    const failedMessage = message?.replace(/^HttpException:\s*/, "");

    yield put(forgotActions.resetFailed({ message: failedMessage }));
    yield delay(1000);
    yield put(forgotActions.resetFailed({}));
  }
}

function* refreshTokenWorker(action: {
  type: typeof authActions.refreshTokenRequest;
  payload: Types.SessionValue;
}): SagaIterator {
  try {
    // Delay for 1 hour (in milliseconds) before triggering the token refresh again
    yield delay(60 * 30 * 1000);
    
    // Make an API call to refresh the token
    const result = yield call(refreshToken, action.payload);

    // Dispatch an action to update the token in the Redux store
    yield put(authActions.refreshTokenUpdate(result.data.accessToken)); // Replace 'UPDATE_TOKEN' with your actual action type

    // Restart the token refresh process by calling the same worker saga recursively
    yield call(refreshTokenWorker, action);
  } catch (error) { /* empty */ }
}

function* handleLoggout(): SagaIterator {
  yield put(dashboardActions.resetDashboard()); // Reset dashboard states
  yield put(signsecureActions.resetSignSecure()); // Reset signsecure states
  yield put(signsecureActions.setRoutes("")); // Reset signsecure states
  yield put(userActions.resetUser()); // Reset users states
}

// Watcher Saga
function* authWatcherSaga(): SagaIterator {
  yield takeEvery(authActions.loginRequest.type, handleSignin);
  yield takeEvery(authActions.faceIDloginRequest.type, handleFaceIDSignin);
  yield takeEvery(signupActions.signupRequest.type, handleSignup);
  yield takeEvery(forgotActions.forgotRequest.type, handleForgotpassword);
  yield takeEvery(forgotActions.resetRequest.type, handleResetpassword);
  yield takeEvery(authActions.refreshTokenRequest.type, refreshTokenWorker);
  yield takeEvery(authActions.logout.type, handleLoggout);
}

export default authWatcherSaga;
