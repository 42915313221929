import { largeSize, mobileSize, tabletSize } from "@src/utils/screensize-helper";
import React from "react";
import colors from "@assets/colors";
import images from "@assets/images";
import SVGIcon from "@assets/svg";
import { Image, Text, View } from "react-native";
import CopyRightContent from "../signin/copy-right";
import useStyles from "./styles.css";

const Logo = () => {
  const styles = useStyles();
  const isTablet = tabletSize();
  const isMobile = mobileSize();
  const isLargeSize = largeSize();

  const size = React.useMemo(() => {
    if(isLargeSize){
      return {
        width: 250,
        height: 50,
      };
    } 
    if (isMobile){
      return {
        width: 181,
        height: 33,
      };
    }
    if (isTablet){
      return {
        width: 224,
        height: 41,
      };
    }

    return {
      width: 279,
      height: 54,
    };
  },[isLargeSize, isMobile, isTablet]);

  return (
    <View style={styles.v_logo_container}>
      <View style={styles.v_image_cover}>
        <Image style={styles.i_logo_background} source={images.bg_signup} />
        <View style={styles.v_logo}>
          <SVGIcon name="signsecure-no-v2" {...size}/>
        </View>
        <Text style={styles.t_notes}>Ensure freedom from{"\n"}fraud at every stage of{"\n"}the signing process.</Text>
      </View>
      <View style={styles.v_logo_terms}>
        <CopyRightContent color={colors.white} termsStyle={styles.termsStyle} /> 
      </View>
    </View>
  );
};

export default Logo;
