import React, { useContext, useMemo, useState } from "react";

// Import Worker
import { DocumentLoadEvent, PageChangeEvent, ScrollMode, Viewer, Worker, ZoomEvent } from "@react-pdf-viewer/core";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
// Import styles of default layout plugin
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useSignsecureService } from "@src/ducks/hooks";
import { selectedEnableSignature } from "@src/ducks/slices/signsecure.slice";
import { disableScrollPlugin } from "@src/utils/pdf-plugin";
import { mobileSize, tabletSize, usePDFScale } from "@src/utils/screensize-helper";
import { getViewport } from "@src/utils/signature-helper";
import _ from "lodash";
import { ActivityIndicator, LayoutChangeEvent, ScrollView, View } from "react-native";
import PageNavigation from "../page-navigation";
import PDFPages from "../pages/mobile";
import { CanvasContext } from "../provider/canvas";
import { PluginContext } from "../provider/plugin";
import KonvaStages from "../stages";
import Thumbnails from "../thumbnails";
import FooterAffix from "../viewer/footer-affix";
import DisplaySignHere from "./display-sign-here";
import useStyles from "./styles.css";

function ViewerScreen(props: any) {
  const { item } = props;
  const styles = useStyles();
  const scale = usePDFScale();
  const isMobileSize = mobileSize();
  const isTabletSize = tabletSize();
  const viewRef = React.useRef<any>();
  const [loading, setLoading] = useState(true);
  const [maxWidth, setMaxWidth] = useState(900);
  const {
    zoomPluginInstance,
    printPluginInstance,
    thumbnailPluginInstance,
    pageNavigationPluginInstance,
  } = useContext(PluginContext);
  const { zoomTo } = zoomPluginInstance;
  const { jumpToPage } = pageNavigationPluginInstance;
  const { viewDocumentChange } = useSignsecureService();
  const disableScrollPluginInstance = disableScrollPlugin();
  const { canvas, onUpdateCanvas } = useContext(CanvasContext);
  const enableSignature = useAppSelector(selectedEnableSignature);
  const { CANVAS_WIDTH, CANVAS_HEIGHT } = useMemo(() => getViewport(canvas, scale),
    [canvas, scale]
  );

  const handleZoom = React.useCallback((e: ZoomEvent) => {
    onUpdateCanvas({ scale: e.scale });
  },[]);

  const handlePageChange = React.useCallback(async (e: PageChangeEvent) => {
    const page = await e.doc.getPage(e.currentPage + 1);
    const { width, height } = page.getViewport({ scale: 1 });
    onUpdateCanvas({
      width,
      height,
      pageIndex: e.currentPage,
    });
  },[]);

  const handleDocumentLoad = React.useCallback((e: DocumentLoadEvent) => {
    setLoading(false);
    const cloneDocument: any = _.cloneDeep(item);
    cloneDocument["numberOfPages"] = e.doc.numPages;
    onUpdateCanvas({ scale: scale });
    viewDocumentChange(cloneDocument);
  }, [item, scale]);

  React.useEffect(() => zoomTo(scale), [scale]);
  React.useEffect(() => jumpToPage(canvas.pageIndex), [canvas.height]);

  const onLayout = (e: LayoutChangeEvent) => {
    setMaxWidth(e.nativeEvent.layout.width);
  };

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <View style={[styles.container]}>
        <Thumbnails height={CANVAS_HEIGHT} />
        <View onLayout={onLayout} style={[styles.v_pdf_wrapper]} >
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={[
              styles.scroll_horizontal,
              {
                maxWidth: maxWidth,
                width: CANVAS_WIDTH,
                height: CANVAS_HEIGHT,
              },
            ]}
          >
            <View
              ref={viewRef}
              style={[
                styles.v_viewer,
                {
                  width: CANVAS_WIDTH,
                  height: CANVAS_HEIGHT,
                },
              ]}
            >
              <Viewer
                fileUrl={item.uri}
                defaultScale={scale}
                enableSmoothScroll={false}
                scrollMode={ScrollMode.Wrapped}
                plugins={[
                  disableScrollPluginInstance,
                  pageNavigationPluginInstance,
                  zoomPluginInstance,
                  printPluginInstance,
                  thumbnailPluginInstance,
                ]}
                onZoom={handleZoom}
                onPageChange={handlePageChange}
                onDocumentLoad={handleDocumentLoad}
                renderLoader={() => (
                  <View
                    style={{
                      paddingTop: 100,
                      width: CANVAS_WIDTH,
                      height: CANVAS_HEIGHT,
                    }} >
                    <ActivityIndicator animating color="black" size={30} />
                  </View>
                )}
              />
              {enableSignature && <KonvaStages 
                loading={loading}
                documentId={item.id}
                layers={item?.workflow?.signatures ?? []} />}
            </View>
          </ScrollView>
          <View style={styles.v_height} />
          {(enableSignature && !isTabletSize) && <DisplaySignHere 
            loading={loading}
            maxWidth={maxWidth}
            layers={item?.workflow?.signatures ?? []}/>}
        </View>
      </View>

      {(isMobileSize || isTabletSize) &&
        <View style={styles.footerIndex}>
          <PDFPages document={item} />
          <PageNavigation />
          <FooterAffix/>
        </View>}
    </Worker>
  );
}

export default ViewerScreen;
