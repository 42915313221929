import React from "react";
import { DrawerContext } from "@src/screens/drawer-screens/drawer-provider";
import { getActiveIndex } from "@src/utils/filter-helper";
import { LiveRequestSteps } from "@src/utils/mockdata";
import SendButton from "./send-button";
import useStyles from "./styles.css";
import { View } from "react-native";
import Items from "./items";

const CDStepsHeader = () => {
  const styles = useStyles();
  const { activeDocument, onSetCreateDocumentScreen } = React.useContext(DrawerContext);
  const activeIndex = React.useMemo(() => getActiveIndex(activeDocument), [activeDocument]);
  
  return (
    <View style={styles.container}>
      <View style={styles.steps_container}>
        {LiveRequestSteps.map((item, index) => {
          return (
            <Items
              key={`stepIdx${index}`}
              item={item}
              disabled={activeIndex < index}
              isActive={activeIndex === index}
              isDone={activeIndex > index}
              onPress={() => onSetCreateDocumentScreen(item)}
            />
          );
        })}
      </View>
      <SendButton />
    </View>
  );
};

export default React.memo(CDStepsHeader);
