import colors from "@assets/colors";
import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    footerIndex: {
      width: "100%",
    },
    footerContainer:{
      width: "100%", 
      height: 65,
      backgroundColor: colors.black3,
      opacity: .8,
    },
    navFooterMobile: {
      backgroundColor: colors.blue,
      width: "100%",
      height: 65,
    },
    alignmentItem: {
      alignItems: "center",
      paddingTop: 21,
      color: colors.white1,
      fontFamily: "DMSans",
      flexDirection: "row",
      justifyContent: "center",
    },
    pageText:{
      color: colors.white1,
      fontFamily: "DMSans",
      fontSize: 20,
      paddingRight: 20,
      paddingLeft: 20
    },
    footerText:{
      fontFamily: "DMSans",
      color: colors.white1,
      flexDirection: "row",
      justifyContent: "space-between",
      paddingLeft: 21,
      paddingRight: 44,
      paddingTop: 21
    },
    container: {
      flex: 1,
      width: "100%", 
      borderRadius: 8,
      flexDirection: "row",
      backgroundColor: colors.white,
    },
    v_pdf_wrapper: {flex: 1, alignItems: "center", height: "100%", marginTop: 10 },
    scroll_vertical: { height: 500 },
    scroll_horizontal: { flexGrow: 1 },
    v_viewer: {
      alignItems: "center",
      justifyContent: "center",
    },
    v_height: { height: 50 },
    loadingStyle: {flex: 1, minWidth: 846, maxWidth: 1420, alignItems: "center", justifyContent: "center"},
  },
  {
    [DEVICE_SIZES.LG]: {
      container: { paddingTop: 10, paddingBottom: 40 },
      loadingStyle: {minWidth: 700, width: 1065 },
    },
    [DEVICE_SIZES.MD]: {
      container: { 
        flexDirection: "column", 
        alignItems: "center", 
        justifyContent: "center",
        paddingBottom: 150 
      },
      v_pdf_wrapper: {width: "100%",  },
      footerContainer:{
        height: 56,
      },
    },
    [DEVICE_SIZES.SM]: {
      container: { 
        justifyContent: "center", 
        borderRadius: 0, 
        height: "100%",
        paddingBottom: 150 
      },
      v_pdf_wrapper: {width: "100%" },
      v_viewer: {
        width: "100%",
        marginTop: 20,
      },
      v_height: { height: 200 },
      scroll_vertical: {
        width: "100%",
        height: "100%",
      },
      scroll_horizontal: {
        width: "100%",
        marginHorizontal: 10,
      },
      footerContainer:{
        height: 56,
      },
    },
    [DEVICE_SIZES.XS]: {
      container: { 
        justifyContent: "center", 
        borderRadius: 0, 
        height: "100%", 
        paddingBottom: 150 
      },
      footerIndex: {
        position: "absolute",
        bottom: 0,
        left: 0,
        alignItems: "flex-end",
        justifyContent: "flex-end"
      },
      footerContainer:{
        height: 56,
      },
    }
  }
);

export default useStyles;
