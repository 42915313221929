import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import colors from "@assets/colors";

export const STEPS_HEADER_HEIGHT = 91;

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flexDirection: "row",
      alignItems: "center",
    },
    steps_container: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    v_align_center: {
      minWidth: 135,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    outer_circle: {
      height: 45,
      width: 45,
      borderRadius: 45 / 2,
      alignItems: "center",
      justifyContent: "center",
      borderWidth: 3,
      borderColor: colors.grayPrimary
    },
    steps_label: {
      fontSize: 14,
      lineHeight: 14,
      marginLeft: 7,
      fontFamily: "DMSans-Medium",
      color: colors.grayPrimary,
    },
    btn_send: { width: 150, height: 47, borderRadius: 8, marginLeft: 35, backgroundColor: colors.blue },
    label_send: { fontSize: 14, lineHeight: 14 },
  },
  {
    [DEVICE_SIZES.LG]: {
      outer_circle: {
        height: 33.75,
        width: 33.75,
        borderRadius: 33.75 / 2,
        borderWidth: 2.25,
      },
      v_align_center: { minWidth: 105 },
      steps_label: { fontSize: 10.5, lineHeight: 10.5 },
      btn_send: { width: 120, height: 35.25, borderRadius: 6 },
      label_send: { fontSize: 10.5, lineHeight: 10.5 },
    },
  }
);

export default useStyles;
