import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedDocumentParties, selectedEnableSignature, selectedSignatureNotes } from "@src/ducks/slices/signsecure.slice";
import { countSignatures } from "@src/utils/validation-helper";
import { subtract } from "lodash";
import React from "react";
import { Text, View } from "react-native";
import { SignatureContext } from "../provider/signature";
import useStyles from "./styles.css";

const CountSignature = () => {
  const styles = useStyles();
  const notes = useAppSelector(selectedSignatureNotes);
  const { document } = React.useContext(SignatureContext);
  const userParty = useAppSelector(selectedDocumentParties);
  const enableSignature = useAppSelector(selectedEnableSignature);
  const signature = React.useMemo(() => countSignatures(document, userParty), [document, userParty]);

  if(["DECLINED", "REJECTED", "CANCELLED", "COMPLETED"].includes(document.workflow.status)
    || ["SIGNED", "APPROVED"].includes(userParty.status)){
    return null;
  }

  return (
    <View style={styles.v_set_signature}>
      <Text style={styles.t_set_signature}>{notes}</Text>
      {(["SIGN", "APPROVE"].includes(userParty.role) && enableSignature) && 
      <Text style={styles.t_set_signature} >{subtract(signature.total, signature.count)} of {signature.total} required fields completed</Text>}
    </View>
  );
};

export default React.memo(CountSignature);
