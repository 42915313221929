import colors from "@assets/colors";
import Material from "@expo/vector-icons/MaterialIcons";
import { AuthContext } from "@src/AuthProvider";
import { useAuthService } from "@src/ducks/hooks";
import * as API from "@src/utils/api";
import moment from "moment";
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { Text, TouchableOpacity, View } from "react-native";
import { SignatureContext } from "../../provider/signature";
import ConfirmFooter from "./confirm-footer";
import DefaultFooter from "./default-footer";
import DropView from "./drop-view";
import SendFooter from "./send-footer";
import "./style.css";
import useStyles from "./styles.css";

const fileTypes = ["JPG", "PNG", "JPEG"];

const UploadFile = ({ onClose }: any) => {
  const styles = useStyles();
  const { accessToken } = useAuthService();
  const [source, setSource] = React.useState<any>(null);
  const [isLoading, setLoading] = React.useState(false);
  const { onErrorMessage } = React.useContext(AuthContext);
  const [generated, setGenerateOTP] = React.useState<any>({});
  const { onSetSignature } = React.useContext(SignatureContext);
  const onTypeError = () =>
    onErrorMessage("Invalid file type. Please use JPG or PNG format.");
  const onSizeError = () =>
    onErrorMessage("Exceeded file size limit of 200KB.");
  const handleChange = (files: any) => {
    if (files) {
      const reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onloadend = (e: any) => {
        setSource({
          name: files.name,
          files: files,
          uri: e.target.result,
        });
      };
    }
  };

  const generateOTP = async () => {
    try {
      setLoading(true);
      const result = await API.generateOTP({ accessToken });

      console.log("generateOTP", result);

      setGenerateOTP({
        creteadAt: +moment(),
        otpMobileHint: result.data.otp,
        sessionId: result.data.otpExpiration,
      });
      setLoading(false);
    } catch (e: any) {
      const message = e.message || e.error || "Somthing went wrong";
      onErrorMessage(message);
    } finally {
      setLoading(false);
    }
  };

  const validateOTP = async (code: string) => {
    try {
      setLoading(true);
      const result = await API.validateOTP({
        otp: code,
        sessionId: generated.sessionId,
        accessToken,
      });

      setLoading(false);
      if(result.data.valid){
        const formData = new FormData();
        formData.append("name", source.name);
        formData.append("file", source.files);
        const result2 = await API.createSignature(formData, accessToken);
        const base64 = await API.downloadSignature(result2.data.id, accessToken);

        onClose();
        onSetSignature({
          signature: base64,
          signatureType: "Upload",
          signatureId: result2.data.id
        });
      }else{
        onErrorMessage("Invalid OTP Code");
      }
    } catch (e: any) {
      const message = e.message || e.error || "Somthing went wrong";
      console.log("generateOTP error", e);
      onErrorMessage(message);
    } finally {
      setLoading(false);
    }
  };

  const Footer = React.useMemo(() => {
    if (source && generated.sessionId) {
      return (
        <ConfirmFooter
          isLoading={isLoading}
          onResend={generateOTP}
          onValidate={validateOTP}
          sessionId={generated.sessionId}
        />
      );
    } else if (source && !generated.sessionId) {
      return (
        <SendFooter
          isLoading={isLoading}
          onNo={() => setSource(null)}
          onYes={generateOTP}
        />
      );
    } else {
      return <DefaultFooter />;
    }
  }, [source, isLoading, generated]);

  return (
    <View style={styles.viewContainer}>
      <View style={styles.v_notes}>
        <View>
          <Text style={styles.t_header}>Upload your signature below</Text>
          <Text style={[styles.t_header, { fontSize: 10 }]}>
            File should be JPG, PNG or PDF. Max file size is 200KB.
          </Text>
        </View>
        {source && (
          <TouchableOpacity
            onPress={() => setSource(null)}
            style={styles.btn_delete} >
            <Material name="delete" size={25} color={colors.purple} />
            <Text style={[styles.t_header, { marginLeft: 5 }]}>Delete</Text>
          </TouchableOpacity>
        )}
      </View>
      <FileUploader
        classes="drop"
        maxSize={0.195}
        types={fileTypes}
        onTypeError={onTypeError}
        onSizeError={onSizeError}
        handleChange={handleChange} >
        <DropView source={source?.uri} />
      </FileUploader>
      {Footer}
    </View>
  );
};

export default UploadFile;
