import { getStateFromPath } from "@react-navigation/native";
import { useAppDispatch, useAppSelector } from "@src/ducks/ducksHook";
import { useDashboardService } from "@src/ducks/hooks";
import { selectAuthLoggedIn } from "@src/ducks/slices/auth.slice";
import { dashboardActions } from "@src/ducks/slices/dashboard.slice";
import { signsecureActions } from "@src/ducks/slices/signsecure.slice";
import { extractAndValidateReferralCode } from "@src/utils/validation-helper";
import * as Linking from "expo-linking";
import qs from "qs";

const prefix = Linking.createURL("/");
const config: any = {
  screens: {
    Signin: "signin",
    SignInWithFaceID: "signin-face-id",
    SignVerification: "/login/google-login",
    SignInBack: "hello-again",
    Signup: "signup",
    Forgot: "forgot",
    ResetPassword: "reset-password/:token",
    "verify-account": "verify-account/:token",
    "verify-success": "verify-success",
    "privacy-policy": "privacy-policy",
    "terms-condition": "terms-condition",
    Home: "home",
    "payment-success": "payment-success",
    "payment-failed": "payment-failed",
    "signup-resend-verification": "signup-resend-verification",
    "team-verification": "subscription/invite",
    "document": "document/:token",
    "delete": "delete"
  },
};
const linking = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);
  const  { onActiveDrawerChange, onDocumentChange } = useDashboardService();

  return {
    prefixes: [prefix],
    config,
    async getInitialURL() {
      const url = await Linking.getInitialURL();
  
      if (url != null) {
        return url;
      }

      return await Linking.getInitialURL();
    },
    subscribe(listener: any) {
      const onReceiveURL = ({ url }: { url: string }) => listener(url);
      const subscription = Linking.addEventListener("url", onReceiveURL);
  
      return () => subscription.remove();
    },
  
    getStateFromPath: (path: string) => {
      const validReferralCode = extractAndValidateReferralCode(path);
      const validationResult = validReferralCode.isValid;
      
      if (path.includes("verify-failed")) {
        return {
          routes: [{ name: "SignIn" }, { name: "verify-failed" }],
        };
      } else if (path.includes("live-request")) {
        return {
          routes: [{ name: "Home" }],
        };
      } else if (path.includes("?q=user-confirmation&token")) {
        const params: any = qs.parse(path.replace("/?", ""));
  
        return {
          routes: [{ name: "SignIn" }, { name: "verification-check", params }],
        };
      } else if (path.includes("/?w=")) {
        const params: any = qs.parse(path.replace("/?", ""));
        params.activeTab = "Affix Document";
        onActiveDrawerChange(params.activeTab);
        onDocumentChange({id: params.w});
  
        return {
          routes: [{ name: "Home" }],
        };
      } else if (path.includes("/document")) {
        if(!isLoggedIn){
          dispatch(dashboardActions.setDocument({id: path.replace("/document/", "")}));
          dispatch(signsecureActions.setRoutes(path));
          return getStateFromPath(path, config);
        }

        onActiveDrawerChange("Affix Document");
        onDocumentChange({id: path.replace("/document/", "")});
  
        return {
          routes: [{ name: "Home" }],
        };
      } else if (path.includes("/payment-success") || path.includes("/payment-failed")) {
        onActiveDrawerChange(path.replace("/", "") === "payment-success" ? "Payment Success" : "Payment Failed");
        
        return {
          routes: [{ name: "Home" }],
        };
      } else if (path.includes("/signup") && validationResult) {
        return {
          routes: [{ name: "Signup" }],
        };
      } else if(path.includes("/delete")) {
        const params: any = qs.parse(path.replace("/?", ""));
        console.log(params)
        onActiveDrawerChange("Delete Account");
        return {
          routes: [{ name: "Home", params }],
        };
      }
  
      return getStateFromPath(path, config);
    },
  };
};

export default linking;
