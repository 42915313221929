import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedAuthGuestRole } from "@src/ducks/slices/auth.slice";
import { tabletSize } from "@src/utils/screensize-helper";
import React from "react";
import { View } from "react-native";
import { DrawerContext } from "../drawer-provider";
import MobileHeader from "./mobile";
import useStyles from "./styles.css";
import WebHeader from "./web-view";
import Header from "../../affix-document/header";

const DrawerHeader = () => {
  const styles = useStyles();
  const isTabletSize = tabletSize();
  const isGuest = useAppSelector(selectedAuthGuestRole);
  const { activeTab } = React.useContext(DrawerContext);

  if(activeTab === "Affix Document"){
    // return null;
    return <Header />
  }

  if(isGuest){
    return isTabletSize ? null :
      <>
        <View style={[styles.drawer_item_header, {  borderBottomWidth: 0 }]}>
        </View>
        <View style={styles.v_height} />
      </>;
  }

  return (
    <>
      <View style={styles.drawer_item_header}>
        {isTabletSize && !isGuest ? <MobileHeader /> : <WebHeader />}
      </View>
      <View style={styles.v_height} />
    </>
  );
};

export default DrawerHeader;
