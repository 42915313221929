import React from "react";
import { SignatureContext } from "./provider/signature";
import useStyles from "./styles.css";
import { View } from "react-native";
import PDFViewer from "./viewer";

function AffixDocument() {
  const styles = useStyles();
  const { document } = React.useContext(SignatureContext);

  return (
    <>
      <View style={styles.container}>
        <View style={styles.body_style}>
          {document.uri && <PDFViewer item={document} />}
        </View>
      </View>
    </>
  );
}

export default React.memo(AffixDocument);
