import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedAuthGuestRole } from "@src/ducks/slices/auth.slice";
import { selectedDrawerStatus } from "@src/ducks/slices/dashboard.slice";
import { largeSize, tabletSize } from "@src/utils/screensize-helper";
import { LinearGradient } from "expo-linear-gradient";
import React, { useEffect } from "react";
import { ScrollView, View, useWindowDimensions } from "react-native";
import Animated, { Extrapolation, interpolate, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
import { SafeAreaView } from "react-native-safe-area-context";
import DrawerGuest from "screens/guest-screen/drawer-component";
import DrawerHeader from "./drawer-header";
import DrawerBody from "./drawer-body";
import DrawerComponent from "./drawer-component";
import DrawerMask from "./drawer-mask";
import useStyles from "./styles.css";
import SupportButton from "./support-button";
import { SignatureProvider } from "../affix-document/provider/signature";

const AnimatedScroll = Animated.createAnimatedComponent(ScrollView);

const MainScreen = () => {
  const styles = useStyles();
  const isLargeSize = largeSize();
  const isTabletSize = tabletSize();
  const { height } = useWindowDimensions();
  const animatedScroll = useSharedValue(0);
  const isGuest = useAppSelector(selectedAuthGuestRole);
  const drawerEnable = useAppSelector(selectedDrawerStatus);

  const animateStyle = useAnimatedStyle(() => {
    const marginLeft = interpolate(
      animatedScroll.value,
      [360, 110],
      [isTabletSize ? 0 : isLargeSize ? 270 : 360, isTabletSize ? 0 : isLargeSize ? 81.15 : 110],
      Extrapolation.CLAMP,
    );

    return { 
      marginLeft,
    };
  }, [isTabletSize, isLargeSize]);

  useEffect(() => {
    animatedScroll.value = withTiming(drawerEnable ? 360 : 110, { duration: 400 });
  },[drawerEnable]);

  return (
    <View style={styles.container}>
      <SafeAreaView />
      <LinearGradient
        colors={["#FFFFFF", "#EDF7FF"]}
        locations={[0.4, 1]}
        style={[styles.linear_style]}
      />
      <View style={[
        styles.drawerContainer, 
        isGuest && styles.guest_drawer,
        { maxHeight: height}
      ]}>
        {isGuest ? <DrawerGuest /> : <DrawerComponent />}
      </View>
      <SignatureProvider>
        <AnimatedScroll 
          style={[
            styles.scrollView, 
            isGuest ? styles.v_guest_view : animateStyle,
            { maxHeight: height}
          ]}
          stickyHeaderIndices={[0]}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          <DrawerHeader />
          <DrawerBody />
          <DrawerMask />
        </AnimatedScroll>
        </SignatureProvider>
      <SupportButton />
    </View>
  );
};

export default React.memo(MainScreen);
