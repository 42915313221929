import _ from "lodash";
import { useCallback, useState } from "react";

// CHECK IF THE FIELDS ARE EMPTY
export const useValidation = () => {
  const [error, setError] = useState<any>({});

  const checkValidation = useCallback(
    (requiredFields: any, stateInput: any) => {
      const newError: any = {};
      const ArrayConvert = _.isArray(requiredFields)
        ? requiredFields
        : Object.keys(requiredFields);
      ArrayConvert.forEach((field: any) => {
        if (!stateInput[field]) {
          newError[field] = "This field is required";
        }
      });

      if (!_.isEmpty(newError)) {
        setError(newError);
      }

      return newError;
    }, []
  );

  const onSetError = useCallback(
    (newError: any) => {
      setError(newError);
    },
    [error]
  );

  return { errorInput: error, checkValidation, onSetError };
};

// CHECK PARTIES HAS DUPLICATE EMAILS
export const hasDuplicateEmails = (array: any[], disableRef: boolean = false) => {
  const emailSet = new Set();

  for (const obj of array) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty("email") && obj.email) {
      if (emailSet.has(obj.email)) {
        !disableRef && obj?.ref?.current?.onError({ email: "Duplicate email" });
        return true; // Duplicate email found
      } else {
        emailSet.add(obj.email);
      }
    }
  }

  return false; // No duplicate emails found
};

// CHECK PARTIES HAS NO SIGNATORY
export const hasNoSignatory = (array: any[]) => {
  for (const obj of array) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty("role") && obj.role) {
      if (obj.role === "Needs to sign") {
        return true; // Signatory role
      }
    }
  }

  return false; // No signatory role found
};

export const hasEmptyReminder = (files: any[]) => {
  const result = files.map((item: any) => {
    if(!_.isEmpty(item.reminders)){
      if(_.isEmpty(item.reminders.expiration) || _.isEmpty(item.reminders.reminderInXDays)
      || _.isEmpty(item.reminders.reminderEveryXDays) || _.isEmpty(item.reminders.reminderBeforeXDays)){
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  });

  return result.includes(true);
};

// DISABLE AFFIX BUTTON IF THERE's ITEM NEEDED TO BE SIGNED
export const countSignatures = (document: any = {}, user: any = {}) => {
  const signatures = document?.workflow?.signatures ?? [];

  const result = signatures.reduce((result: { total: number, count: number, checkCount: number}, value: any) => {
    value.forEach((item: any) => {
      if(["signature", "initials", "signature-name", "signature-date", 
        "signature-name-designation"].includes(item.type) && !item.signature && item.trackId === user.trackId){
        result.count += 1;
      }else if(item.type === "textbox" && !item.text && item.trackId === user.trackId){
        result.count += 1;
      }

      if(["signature", "initials", "signature-name", "signature-date", 
        "signature-name-designation", "textbox"].includes(item.type) && item.trackId === user.trackId){
        result.total += 1;
      }

      if(item.type === "checkbox" && !result.checkCount && item.trackId === user.trackId){
        result.total += 1;
        result.checkCount = 1;
      }
    });

    if(value.find((item: any) => item.type === "checkbox" && item.isChecked)){
      result.checkCount = 0;
    }

    return result;
  },{total: 0, count: 0, checkCount: 0});

  return { total: result.total, count: result.count + result.checkCount};
};

// CHECK CHARACTER IF MORE THAN 37 CHAR THEN TRIM THE REST
export const characterTrim = (char: string, length: number = 37) => {
  return char.length > length ? char.substring(0, length) + "..." : char;
};

// CHECK SIGNATURE IMAGE IF JPEG/JPG
export const isJPEGFormat = (signature: string) => {
  return signature.startsWith("data:image/jpeg;base64") || signature.startsWith("data:image/jpg;base64");
};

export const isIOSafari = () => {
  /*
      Returns (true/false) whether device agent is iOS Safari
  */
  const ua = navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);

  return iOS || navigator.vendor.match(/apple/i);
};

/*
  * Validate the received referral code in URL path
  * Returns referralCode and if its valid object
*/
export const extractAndValidateReferralCode = (path: any) => {
  const referralCode = _.split(path, "/signup/")[1];
  const isValid = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{8}$|^[a-zA-Z]{8}$/.test(referralCode);
  return { referralCode, isValid };
};

export const isiPhoneSafari = () => {
  /*
      Returns (true/false) whether device agent is iOS Safari
  */
  const ua = navigator.userAgent;
  const iOS13_iPad = (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

  if(ua.match(/iPad/i) || ua.match(/iPhone/i) || iOS13_iPad){
    return true;
  }

  return true;
};