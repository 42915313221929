import colors from "@assets/colors";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectAuthLoggedIn, selectedAuthGuestRole } from "@src/ducks/slices/auth.slice";
import MobileHeader from "@src/screens/drawer-screens/drawer-header/mobile";
import WebHeader from "@src/screens/drawer-screens/drawer-header/web-view";
import { mobileSize, tabletSize } from "@src/utils/screensize-helper";
import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { View } from "react-native";
import { SignatureContext } from "../provider/signature";
import AffixSignature from "./affix-signature";
import BackButton from "./affix-signature/back-button";
import CountSignature from "./count-signature";
import useStyles from "./styles.css";
import Logo from "./logo";


const Header = () => {
  const styles = useStyles();
  const isTabletSize = tabletSize();
  const isMobileSize = mobileSize();
  const isGuest = useAppSelector(selectedAuthGuestRole);
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);
  const { screen } = React.useContext(SignatureContext);

  if(screen !== ""){ return null; }

  if(!isLoggedIn){
    return (
      <>
        <View style={styles.header_document}>
          <LinearGradient
            colors={[colors.colorPrimary, colors.blue]}
            style={styles.linearStyle}
          />
          <Logo />
          <AffixSignature color={colors.white} />
        </View>
        <View style={styles.header_document_padding}>
          {!isMobileSize && <CountSignature />}
        </View>
        <View style={styles.v_height} />
      </>
    );
  }

  return (
    <View style={styles.header_background}>
      <View style= {isGuest ? styles.guestDisplay : styles.drawer_item_header}>
        {isTabletSize && !isGuest ? <MobileHeader /> : <WebHeader/>}
      </View>
      <View style={styles.header_container}>
        {(isTabletSize || isGuest) && <BackButton />}
        <AffixSignature />
      </View>
      {isMobileSize ? "" : <CountSignature />}
      {/* <View style={styles.v_height} /> */}
    </View>
  );
};

export default Header;
