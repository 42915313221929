import React, { memo } from "react";
import SignatureFullNameDesignation from "./signature-fullname-designation";
import SignatureFullname from "./signature-fullname";
import SignatureDate from "./signature-date";
import Designation from "./designation";
import Signature from "./signature";
import FullName from "./full-name";
import DateTime from "./date-time";
import QRCodeSign from "./qrcode";
import Initials from "./initials";
import Checkbox from "./checkbox";
import TextBox from "./textbox";
import Text from "./text";

type ItemsProps = {
  field: any;
  onSelect: () => void;
  onCheck: () => void;
  onTextFocus: () => void;
  onTextUpdate: (item: any) => void;
  onSelectTextBox: () => void;
  onQuickSign: () => void;
};

const Items = (props: ItemsProps) => {
  if (props.field.type === "signature") {
    return <Signature field={props.field} onSelect={props.onSelect} onQuickSign={props.onQuickSign} />;
  } else if (props.field.type === "signature-name") {
    return <SignatureFullname field={props.field} onSelect={props.onSelect} onQuickSign={props.onQuickSign} />;
  } else if (props.field.type === "signature-date") {
    return <SignatureDate field={props.field} onSelect={props.onSelect} onQuickSign={props.onQuickSign} />;
  } else if (props.field.type === "signature-name-designation") {
    return <SignatureFullNameDesignation field={props.field} onSelect={props.onSelect} onQuickSign={props.onQuickSign} />;
  } else if (props.field.type === "full-name") {
    return <FullName field={props.field} />;
  } else if (props.field.type === "initials") {
    return <Initials field={props.field} onSelect={props.onSelect} onQuickSign={props.onQuickSign} />;
  } else if (props.field.type === "designation") {
    return <Designation field={props.field} />;
  }  else if (props.field.type === "date-time") {
    return <DateTime field={props.field} />;
  } else if (props.field.type === "qrcode_sign") {
    return <QRCodeSign field={props.field} />;
  } else if (props.field.type === "textbox") {
    return <TextBox field={props.field} onTextFocus={props.onTextFocus} onTextUpdate={props.onTextUpdate} onSelectTextBox={props.onSelectTextBox} />;
  } else if (props.field.type === "checkbox") {
    return <Checkbox field={props.field} onCheck={props.onCheck} />;
  } 

  return (
    <Text field={props.field} />
  );
};

export default memo(Items);
