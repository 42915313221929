import PopupMenuProvider from "@src/components/popup-menu/provider";
import { gtag } from "ga-gtag";
import React from "react";
import { DrawerProvider } from "./drawer-provider";
import MainScreen from "./main-screen";
// import { SignatureProvider } from "../affix-document/provider/signature";

const DrawerScreen = () => {
  React.useEffect(() => {
    gtag("event", "homepage");
  }, []);
  
  return (
    <DrawerProvider>
      <PopupMenuProvider>
        {/* <SignatureProvider> */}
          <MainScreen />
        {/* </SignatureProvider> */}
      </PopupMenuProvider>
    </DrawerProvider>
  );
};

export default DrawerScreen;
