import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedDocumentParties } from "@src/ducks/slices/signsecure.slice";
import { countSignatures } from "@src/utils/validation-helper";
import { subtract } from "lodash";
import React from "react";
import { View } from "react-native";
import { SignatureContext } from "../provider/signature";
import useStyles from "./styles.css";

function FooterAffix() {
  const styles = useStyles();
  const { document } = React.useContext(SignatureContext);
  const userParty = useAppSelector(selectedDocumentParties);
  const signature = React.useMemo(() => countSignatures(document, userParty), [document, userParty]);
  
  if(["DECLINED", "REJECTED", "CANCELLED", "COMPLETED"].includes(document.workflow.status)
    || ["SIGNED", "APPROVED"].includes(userParty.status)){
    return null;
  }

  return (
    <>
      <View style={styles.footerContainer}>
        <View style={styles.footerText}>
          <View>{subtract(signature.total, signature.count)} of {signature.total} required fields completed</View>
        </View>
      </View>
    </>
  );
}

export default FooterAffix;
