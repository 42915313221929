import { useNavigation } from "@react-navigation/native";
import { TextInput02 } from "@src/components/textinput";
import { useAuthService } from "@src/ducks/hooks";
import { NavigationScreenProps } from "@src/navigators/NavigatorTypes";
import useOrientationHelper from "@src/utils/orientation-helper";
import _ from "lodash";
import React, { useState } from "react";
import { Text, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import ActionButton from "./action-button";
import ErrorView from "./error";
import GoogleButton from "./google-button";
import Forgot from "./remember-me";
import useStyles from "./styles.css";
import UnawaProduct from "./unawaProduct";

interface ErrorProps {
  email: string;
  password: string;
}

const ErrorValue = {
  email: "",
  password: "",
};

const Login = () => {
  const styles = useStyles();
  const errorRef = React.useRef<any>(null);
  const isLandScape = useOrientationHelper();
  const [error, setError] = useState<ErrorProps>(ErrorValue);
  const navigation = useNavigation<NavigationScreenProps<"Home">>();
  const { loginInput, setLoginInput, onSignin, 
    isLoading, onResetLoading } = useAuthService();

  const onSignUp = () => navigation.navigate("Signup");

  const onChangeInput = (type: string) => (value: any) => {
    setLoginInput({ type, value });
    setError({} as ErrorProps);
    errorRef.current?.onClear();
  };

  const onSigningIn = () => {
    const newError: any = {};
    if (!loginInput.email) {
      newError.email = "Email is required.";
    }
    if (!loginInput.password) {
      newError.password = "Password is required.";
    }

    setError(newError);

    if (_.isEmpty(newError)) {
      onSignin(loginInput);
    }
  };

  const onSignInWithFaceID = () => {
    const newError: any = {};
    if (!loginInput.email) {
      newError.email = "Email is required.";
    }

    setError(newError);

    if (_.isEmpty(newError)) {
      navigation.navigate("SignInWithFaceID");
    }
  };

  const handleEnterKeyPress = (e: any) => { 
    if (e.key === "Enter") { 
      onSigningIn(); 
    }
  };

  React.useEffect(() => {
    onResetLoading();
    window.addEventListener("keypress", handleEnterKeyPress);

    return () => {
      window.removeEventListener("keypress", handleEnterKeyPress);
    };
  }, []);

  return (
    <View style={isLandScape ? styles.v_login_landscape : styles.v_login} >
      <ScrollView showsVerticalScrollIndicator={false} 
        style={styles.scrollview}>
        <View style={isLandScape ? styles.v_height_top_landscape : styles.v_height_top} />
        <View style={styles.signInCenter}>
          <Text style={styles.txtNote3}>Sign in to begin</Text>
          <Text style={styles.txtNote4}>
            Enter your password or sign in with face recognition
          </Text>
        </View>
        <ErrorView ref={errorRef} />
        <View style={styles.v_marginTop20}>
          <TextInput02
            value={loginInput.email}
            label="Email Address"
            labelStyle={styles.labelStyle}
            error={error.email}
            onChangeText={onChangeInput("email")}
            placeholder="Enter your email address"
            onKeyPress={handleEnterKeyPress}
          />
          <TextInput02
            hasPassword
            value={loginInput.password}
            label="Password"
            labelStyle={styles.labelStyle}
            error={error.password}
            onChangeText={onChangeInput("password")}
            placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
            onKeyPress={handleEnterKeyPress}
          />
          <Forgot 
            isCheck={loginInput?.rememberme}
            onCheck={() => onChangeInput("rememberme")(!loginInput?.rememberme)} />
          <ActionButton
            email={loginInput.email}
            isLoading={isLoading}
            onSigningIn={onSigningIn}
            onSignInWithFaceID={onSignInWithFaceID}
          />
          <View style={{flexDirection: "row", flex: 1, marginTop: 50, justifyContent: "center"}}>
            <View style={styles.itemSeparator} />
            <Text style={styles.txtNote5}> 
                or Sign In With 
            </Text>
            <View style={styles.itemSeparator} />
          </View>
          <GoogleButton />
          <Text style={styles.t_signup}>
            Don’t have an account yet?
            <Text style={styles.t_signup_2} onPress={onSignUp}>
              Sign up now!
            </Text>
          </Text>
        </View>
        {isLandScape ? null : <UnawaProduct />}
      </ScrollView>
    </View>
  );
};

export default Login;