import React, { useState, useCallback, createContext } from "react";
import { useFetchPost, useFetchPut } from "@src/utils/api/hooks";
import _ from "lodash";
import moment from "moment";

type ResetPassword = {
  password: string;
  confirmPassword: string;
};

export interface ContextValue {
  email: string;
  screen: string;
  generated: number;
  loading: boolean;
  sentEmailResponse: object;
  failed: { message: string};
  verifyFailed: { message: string};
  newpasswordFailed: { message: string};
  onSetEmail: (params: string) => void;
  onSendEmail: () => void;
  onVerifyCode: (otp: string) => void;
  onNewPassword: (params: ResetPassword) => void;
}

interface ProviderProps {
  children: React.ReactElement;
}

const ForgotPasswordContext = createContext<ContextValue>({
} as ContextValue);

const ForgotPasswordProvider = ({ children }: ProviderProps) => {
  const [otp, setOTP] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [screen, setScreen] = useState<string>("");
  const [generated, setGenerated] = useState<number>(0);
  const {loading, data, error, runRequest} = useFetchPost();
  const {loading: NPLoading, data: NP, error: errorNP, runRequest: runRequestNP} = useFetchPut();
  const {loading: VerifyLoading, error: errorVerify, data: Verify, runRequest: runRequestVerify} = useFetchPost();

  const onSetEmail = useCallback((value: string) => {
    setEmail(value);
  }, [email]);

  const onSendEmail = useCallback(() => {
    runRequest("/auth/forgot-password", { email });
  }, [email]);

  const onVerifyCode = useCallback((otp: string) => {
    runRequestVerify("/auth/verify-reset-otp", { email, otp});
    setOTP(otp);
  }, [email]);

  const onNewPassword = useCallback((params: ResetPassword) => {
    runRequestNP("/auth/reset-password", {...params, otp});
  }, [otp]);

  React.useEffect(() => {
    if(!_.isEmpty(data)){
      setScreen("verification");
      setGenerated(+moment());
    }
    if(!_.isEmpty(Verify)){
      setScreen("new-password");
    }
    if(!_.isEmpty(NP)){
      setScreen("success");
    }
  }, [data, Verify, NP]);

  const value = {
    email,
    screen,
    generated,
    sentEmailResponse: data,
    loading: loading || NPLoading || VerifyLoading,
    failed: error,
    verifyFailed: errorVerify,
    newpasswordFailed: errorNP,
    onSetEmail,
    onSendEmail,
    onVerifyCode,
    onNewPassword,
  };

  return (
    <ForgotPasswordContext.Provider value={value}>
      {children}
    </ForgotPasswordContext.Provider>
  );
};

export { ForgotPasswordProvider, ForgotPasswordContext };
